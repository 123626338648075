export const recruitTextConfig =   {
    bannerTitle:"人材募集", //大标题
    bannerDesc:"優秀なあなたを歓迎して私達に参加して、あなたの到来を期待します。", //标题描述
    //policy数据
    policyData:[
        {
            index:0, //序号
            number:'01', //激活逻辑相关
            titleColor:'linear-gradient(180deg,rgba(255,218,103,1) 0%,rgba(233,136,64,1) 100%)', //数字方块样式
            textColor:'rgba(233,135,64,0.1)', //文字颜色
            title:'好奇心が旺盛な人', //标题
            text:'SEはコンピューター技術の最前線にいますが、理数系出身でなくても構いません。モノをつくる喜びがわかり、新しいものにチャレンジできるフレシキブルな精神があればいいのです。要するに好奇心が強く自分から行動できる人ほど面白く、また奥深い仕事のできるSEになれます。コンピューター技術に挑戦したいという方を待っています。',
            //内容
            reverse:false, //左右样式
        },
        {
            index:1,
            number:'02',
            titleColor:'linear-gradient(180deg,rgba(103,156,255,1) 0%,rgba(64,77,233,1) 100%)',
            textColor:'rgba(64,78,233,0.1)',
            title:'チームプレイができる人',
            text:'SEの仕事は一人でなく、チームを組んでおこないます。個人のスキルを高めることは勿論必要ですが、チームとして成果を出せなければ意味が有りません。人それぞれ個性や経験の違いはありますが、その時々の自分の役割を十分に理解し、チームとして最大限の力を発揮できるように努力できる方を求めています。',
            reverse:true,
        },
        {
            index:2,
            number:'03',
            titleColor:'linear-gradient(180deg,rgba(103,236,255,1) 0%,rgba(64,119,233,1) 100%)',
            textColor:'rgba(63,119,233,0.1)',
            title:'倫理観と責任感のある人',
            text:'当社の扱うシステムは社会インフラの根幹を成すシステムです。ですから小さいもので数十万人、大きくなると数千万人の人々の生活に影響します。そのためやりがいや達成感は大きいのですが、応分に社会的責任や高い倫理観も求められます。世の中を便利にするため、何ができるかを考え行動できる方に入社して欲しいと考えています。',
            reverse:false,
        },
    ],
    //Q&A数据
    QandA:[
        {
            Q:'コンピュータやプログラムの知識は必要ですか？',
            A:'A：技術面は研修で基礎から教えますので特に必要ありません。それより今は、学生時代にしかできない勉強や遊びに力をいれてください。特に、友人を増やすとか目上の人と接する機会を増やす等。'
        },
        {
            Q:'文系・理系、男女による仕事内容や待遇の違いはありますか？',
            A:'A：全くありません。文理関係なく仕事をしていますし、女性でもマネージャーとしてプロジェクトを運営管理している人もいます。入社後の成長度合いにより、段階的に仕事のレベルを上げていきますので、少しずつ成長していってください。'
        },
        {
            Q:'選考のスケジュールはどのようになっていますか？',
            A:'A：まずは会社説明会へご参加いただきどんな会社かみてください。その上で「当社に入社したい！」「興味があるから受けてみようかな」と思われた方は、試験の申し込みをしてください。'
        },
        {
            Q:'残業はどの程度ありますか？',
            A:'A：当社の平均残業時間は月30時間程度です。ただ、プロジェクトごと納期がありますので、納期前の作業の進行状況によって残業が増える場合もありますし、逆に納期前でも予定通り順調にいっていれば定時で帰る場合もあります。'
        }
    ],
    //毕业生招聘页数据
    Graduated:{
        //待遇描述
        givenData:[
            {
                title:'給与',
                desc:'年齢・経験・能力を考慮の上、当社規定により優遇致します。例：35歳 システムエンジニア … 年収450万円以上'
            },
            {
                title:'昇給・賞与',
                desc:'昇給年一回(7月)、賞与年二回(6月、12月)'
            },
            {
                title:'各種手当て',
                desc:'交通費全額支給、子供手当、時間外手当など'
            },
            {
                title:'勤務地',
                desc:'都内近郊および首都圏'
            },
            {
                title:'勤務時間',
                desc:'9:00～17:50'
            },
            {
                title:'休日・休暇',
                desc:'完全週休二日制（土、日）、祝日、年末年始、年次有給休暇（繰越制）、慶弔休暇ほか'
            },
            {
                title:'福利厚生',
                // desc:'各種社会保険（健康保険、厚生年金保険、雇用保険、労災保険）企業型確定拠出年金、親睦会、各種クラブ活動（野球、フットサルなど）、契約保養所ほか'
                desc:'・各種社会保険完備<br/>・資格取得支援<br/>・社外研修参加支援制度<br/>・海外研修制度<br/>・懇親会<br/>・各種クラブ活動<br/>・産休育休制度<br/>・慶弔見舞金制度<br/>・服装自由'
            }
            // ,
            // {
            //     title:'諸制度',
            //     desc:'退職金制度、財形貯蓄制度、社員持株会制度、慶弔見舞金制度、社外研修参加支援制度、海外研修制度'
            // }
        ],
        //岗位信息
        positionData:[
            {
                title:'PG',
                num:'10人',
                conditions:[
                    '■必須条件：',
                    '◆新卒',
                    '主要言語（Kotlin、Dart、Swift）を用いたモバイルアプリケーションの開発経験',
                    '最新の技術に注目、精通しながら開発できる',
                    '■歓迎条件：',
                    '◆オブジェクト指向、ネットワーク、セキュリティ等の技術に関する知識',
                    '◆ブログなどで技術情報やプログラムを公開している',
                    '◆Webサービスに関する知識',
                    '◆チームで開発した経験',
                    '◆Gitを使用した開発経験'
                ]
            },
            // {
            //     title:'PL',
            //     num:'3人',
            //     conditions:[
            //         '◆学歴不問',
            //         '◆システム開発経験10年以上',
            //         '=== PL未経験の方も歓迎 ===',
            //         '未経験から大手のプロジェクトに参画できるため、',
            //         'これからマネジメント層を目指したいという方にとって魅力的な環境です。',
            //         'あなたの意欲を重視して、お話をさせていただきたいと考えています。',
            //     ]
            // },
            // {
            //     title:'SL',
            //     num:'5人',
            //     conditions:[
            //         '■必須条件：社会人経験2年以上かつ、以下のいずれかを1つでも満たす方',
            //         '◆組込ソフトウェア設計の経験',
            //         '◆OS（Linux、リアルタイムOS等）ポーティングの経験',
            //         '◆組込製品のシステム設計、開発経験'
            //     ]
            // },
            // {
            //     title:'PG',
            //     num:'10人',
            //     conditions:[
            //         '【開発環境】',
            //         '■言語：Java、ASP.net、C#、VB.net',
            //         '■技術：Struts、SOAP',
            //         '■環境：Apache、Tomcat、CentOS、Oracle、PostgreSQL、SQLServer',
            //         '※他、Java、C++、C#などの言語を中心とした開発案件'
            //     ]
            // },
        ]
    },
    //社会招聘页面数据
    Social:{
        //待遇描述
        givenData:[
            {
                title:'給与',
                desc:'年齢・経験・能力を考慮の上、当社規定により優遇致します。例：35歳 システムエンジニア … 年収450万円以上'
            },
            {
                title:'昇給・賞与',
                desc:'昇給年一回(7月)、賞与年二回(6月、12月)'
            },
            {
                title:'各種手当て',
                desc:'交通費全額支給、子供手当、時間外手当など'
            },
            {
                title:'勤務地',
                desc:'都内近郊および首都圏'
            },
            {
                title:'勤務時間',
                desc:'9:00～17:50'
            },
            {
                title:'休日・休暇',
                desc:'完全週休二日制（土、日）、祝日、年末年始、年次有給休暇（繰越制）、慶弔休暇ほか'
            },
            {
                title:'福利厚生',
                // desc:'各種社会保険（健康保険、厚生年金保険、雇用保険、労災保険）企業型確定拠出年金、親睦会、各種クラブ活動（野球、フットサルなど）、契約保養所ほか'
                desc:'・各種社会保険完備<br/>・資格取得支援<br/>・社外研修参加支援制度<br/>・海外研修制度<br/>・懇親会<br/>・各種クラブ活動<br/>・産休育休制度<br/>・慶弔見舞金制度<br/>・服装自由'
            }
            // ,
            // {
            //     title:'諸制度',
            //     desc:'退職金制度、財形貯蓄制度、社員持株会制度、慶弔見舞金制度、社外研修参加支援制度、海外研修制度'
            // }
        ],
        //岗位信息
        positionData:[
            {
                title:'PM',
                num:'2人',
                conditions:[
                    '■必須条件：',
                    '◆プロジェクトの管理者(マネージャー、リーダー)として進捗、品質、問題管理の実務のご経験',
                    '',
                    '■尚可：',
                    '◆プロジェクト計画の策定、実行、完了までのライフサイクルのご経験',
                    '◆ステークホルダーとのコミュニケーション、報告のご経験',
                    '各種基盤系構築、更改プロジェクトのリーダーのご経験(クラウド環境、仮想環境、オンプレ環境)',
                    '◆オフショア、ニアショア開発のブリッジSE、リーダーのご経験'
                ]
            },
            {
                title:'PL',
                num:'3人',
                conditions:[
                    '◆学歴不問',
                    '◆システム開発経験10年以上',
                    '=== PL未経験の方も歓迎 ===',
                    '未経験から大手のプロジェクトに参画できるため、',
                    'これからマネジメント層を目指したいという方にとって魅力的な環境です。',
                    'あなたの意欲を重視して、お話をさせていただきたいと考えています。',
                ]
            },
            {
                title:'SE',
                num:'5人',
                conditions:[
                    '■必須条件：社会人経験5年以上かつ、以下のいずれかを1つでも満たす方',
                    '◆組込ソフトウェア設計の経験',
                    '◆OS（Linux、リアルタイムOS等）ポーティングの経験',
                    '◆組込製品のシステム設計、開発経験'
                ]
            },
            // {
            //     title:'PG',
            //     num:'10人',
            //     conditions:[
            //         '【開発環境】',
            //         '■言語：Java、ASP.net、C#、VB.net',
            //         '■技術：Struts、SOAP',
            //         '■環境：Apache、Tomcat、CentOS、Oracle、PostgreSQL、SQLServer',
            //         '※他、Java、C++、C#などの言語を中心とした開発案件'
            //     ]
            // }
        ]
    }
}